import React from "react";
import "tailwindcss/tailwind.css";
import { createBrowserRouter } from "react-router-dom";
import { Layout } from "./layout/Layout";
import { Home } from "@pages/Home/ui/Home";
import { Form } from "@pages/Form/ui/Form";

const CustomRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <span>404</span>,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "Form",
        element: <Form />,
      },
    ],
  },
]);

export default CustomRouter;
