import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const Bottom = () => {
  const navigate = useNavigate();
  const [uuid, setUuid] = useState<string>();

  useEffect(() => {
    let uuid = localStorage.getItem("uuid");
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }
    setUuid(uuid);
  }, []);

  const btnHandler = () => {
    const fetch = async () => {
      try {
        const res = await axios.post("https://violetpay.net/promo/collect2", {
          session_id: uuid,
          project: "murimiully",
          pageType: "a",
          event: "bottom-button-click",
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    navigate("/form");
  };

  return (
    <div className="flex flex-col justify-center items-center bg-p3_black w-full">
      <div className="flex flex-col justify-center items-center text-center mb-10">
        <p className="text-p3_white font-roboto font-extrabold text-[18px] mt-12 mb-4">
          MURIMULLY
        </p>
        <h3 className="text-p3_white text-[20px]">진짜 헤어스타일을 만나는</h3>
        <h3 className="text-p3_white text-[20px] mb-8">새로운 방법</h3>
        <button
          className="flex justify-center items-center w-[148px] h-[48px] bg-p3_purple rounded-full"
          onClick={btnHandler}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            className="mr-3"
          >
            <path
              d="M8.99094 7.45284C8.99181 6.96557 9.15057 6.49122 9.44425 6.09845C9.73793 5.70567 10.1513 5.41486 10.6245 5.26809C10.4412 4.9365 10.1887 4.64661 9.88349 4.41736C9.57831 4.1881 9.22731 4.02464 8.85342 3.93764C8.47953 3.85065 8.09115 3.84207 7.71366 3.91248C7.33616 3.98289 6.97804 4.1307 6.66268 4.34625C6.51996 4.42716 6.35807 4.46976 6.19327 4.46976C6.02848 4.46976 5.86658 4.42716 5.72387 4.34625C5.38252 4.13367 4.99969 3.99347 4.59994 3.93466C4.20018 3.87585 3.79232 3.89972 3.40251 4.00474C3.0127 4.10976 2.64954 4.29361 2.33633 4.54451C2.02311 4.7954 1.76676 5.1078 1.5837 5.46167C1.06071 6.40741 0.88188 7.50002 1.07674 8.55904C1.23495 10.0135 1.87477 11.3769 2.89804 12.44C3.23536 12.7542 3.67535 12.9405 4.13959 12.9659C4.60383 12.9912 5.06214 12.8538 5.43283 12.5782C5.64615 12.4233 5.90447 12.3397 6.1698 12.3397C6.43513 12.3397 6.69346 12.4233 6.90677 12.5782C7.26249 12.8604 7.70853 13.0097 8.16571 12.9996C8.62288 12.9894 9.06162 12.8206 9.40402 12.5229C10.1769 11.7568 10.7278 10.8022 11 9.75742C10.4361 9.68088 9.92029 9.40412 9.5501 8.97947C9.17991 8.55483 8.98098 8.01173 8.99094 7.45284Z"
              fill="white"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.11331 2.38275L8.52153 1"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-p3_white">App Store</p>
        </button>
      </div>
    </div>
  );
};
