import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Form = () => {
  const navigate = useNavigate();

  const [btnDisable, setBtnDisable] = useState<boolean>(true);
  const [inputDisable, setInputDisable] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const [uuid, setUuid] = useState<string>();

  useEffect(() => {
    let uuid = localStorage.getItem("uuid");
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }
    setUuid(uuid);
  }, []);

  useEffect(() => {
    if (inputValue.trim() === "") {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [inputValue]);

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const btnHandler = () => {
    const fetch = async () => {
      try {
        const res = await axios.post("https://violetpay.net/promo/collect2", {
          session_id: uuid,
          project: "murimiully",
          pageType: "a",
          event: "instagram-input",
          content: inputValue,
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
    setInputValue("");
    setInputDisable(true);
    navigate("/form");
  };

  return (
    <div className="flex flex-col w-full h-[640px] bg-p3_white p-4 overflow-hidden">
      <div className="mt-4">
        <p className="text-[#5E5E5E] font-pretendardLight text-[14px]">
          Share your hairstyles
        </p>
        <h1 className="text-[#030303] font-roboto text-[23px] font-extrabold">
          MURIMULLY
        </h1>
      </div>
      <div className="mt-2 text-[#030303] font-pretendardLight text-[15px]">
        <p>안녕하세요. MURIMULLY [머리멀리]입니다!</p>
        <p>먼저, 저희 서비스에 관심을 주신 초기유저분들께</p>
        <p>농도짙은 감사함을 드립니다. 🙇‍♂️</p>
        <br />
        <p>해당 페이지까지 오신 분들을 대상으로</p>
        <div className="flex">
          <p className="text-[#9A5CFF] font-semibold">서비스 출시 전 인터뷰</p>
          <p>를 진행하고 싶어요!</p>
        </div>

        <p>여러분의 목소리가 서비스를 만드는데에</p>
        <p>정말 소중한 도움이 될 것 같습니다.</p>
        <p>희망하시는 분은 편하게</p>
        <p>아래 인스타 아이디를 넣어주세요!</p>
        <p>확인 후 바로 DM 드리겠습니다! 😊</p>
      </div>
      <div className="relative bg-[#F8F8F8] h-[144px] rounded-[10px] p-4 mt-5">
        <div
          className="absolute text-center content-center w-[157.749px] h-[31px] bg-[#F2E2FF]"
          style={{
            transform: "rotate(-3.702deg)",
            right: "-35px",
            bottom: "50px",
          }}
        >
          <p className="text-[#9A5CFF] font-pretendard text-[12px] font-semibold">
            와! 시급 4만원 알바!
          </p>
        </div>
        <div className="text-[#000] font-pretendard font-semibold pt-3">
          <p>예상 인터뷰 시간 : 10분 ~ 30분 이내</p>
          <p>
            장소 :{" "}
            <a
              href="https://m.place.naver.com/place/1042227506/home"
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-[#9A5CFF]"
            >
              스테이지9 삼성점
            </a>
          </p>
          <p>사례 : 40,000원</p>
        </div>
        <p className="text-[12px] text-[#3D3D3D] float-end w-[90%]">
          + 커피, 쌩-맥주🍺, 다과 제공가능 +{" "}
          <a
            href="https://www.instagram.com/murimully.official/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            official계정 맞팔
          </a>
        </p>
      </div>
      <div className="flex mt-4 gap-2">
        <input
          type="text"
          className="w-full h-[50px] rounded-[10px] bg-[#F1F1F1] px-5"
          placeholder="@instagram_username"
          value={inputValue}
          onChange={inputChangeHandler}
          disabled={inputDisable}
        />
        <button
          className={`w-[86px] h-[50px] bg-[#9A5CFF] rounded-[10px] text-p3_white font-bold ${
            btnDisable ? "bg-[#CDCDCD]" : "bg-[#9A5CFF]"
          }`}
          disabled={btnDisable}
          onClick={btnHandler}
        >
          전송
        </button>
      </div>
    </div>
  );
};
