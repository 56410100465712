import React from "react";
import { Top } from "../../../widget/Top";
import { Main } from "../../../widget/main";
import { Bottom } from "../../../widget/Bottom";

export const Home = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full">
      <Top />
      <Main />
      <Bottom />
    </div>
  );
};
