import React from "react";

export const Main = () => {
  // const getRandomImage = () => {
  //   const images = ["murimullyW.jpg", "murimullyM.png"];
  //   const randomIndex = Math.floor(Math.random() * images.length);
  //   return images[randomIndex];
  // };

  // const selectedImage = getRandomImage();
  return (
    <div className="w-full">
      {/* <div className="object-cover">
        <img src="murimullyW.jpg" alt="allImgW" className="w-full"></img>
      </div> */}
      <div className="object-cover">
        <img src="murimullyM.png" alt="allImgM" className="w-full"></img>
      </div>
      {/* <div className="object-cover">
        <img src={selectedImage} alt="randomImg" className="w-full"></img>
      </div> */}
    </div>
  );
};
